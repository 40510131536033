import DynamicSectionElement from "@/scripts/dynamic-section-element.js";

class SiteHeader extends DynamicSectionElement {
  constructor() {
    super();

    let menuWrapper = this.querySelector("#menu-wrapper");
    let menu = this.querySelector(".menu");
    let menuLink = this.querySelectorAll(".menu-link");
    let menuDropdown = menu.querySelectorAll(".menu-dropdown-wrapper");
    let menuButton = this.querySelector("#open-mobile-menu");
    let overlay = this.querySelector("#content-overlay");
    let annoucmentbar = document.querySelector(".announcement-bar");
    let menuActive = false;
    const pseudoNav = this.querySelector("#pseudo-nav");
    let lastOpen = null;

    let mobile = window.matchMedia("(max-width: 1023px)");
    let desktop = window.matchMedia("(min-width: 1024px)");

    menu?.addEventListener("mouseover", () => {
      menuActive = true;
    });
    menu?.addEventListener("mouseout", () => {
      menuActive = false;
    });

    window.addEventListener("scroll", () => {
      if (window.scrollY >= 41) {
        this.dataset.scrolled = true;
        overlay.classList.add("desktop-hide");
        pseudoNav.classList.add("desktop-hide");
      } else {
        this.dataset.scrolled = false;
      }
    });

    if (desktop.matches) {
      menuLink.forEach((element) => {
        let dropdownWrapper = element.querySelector(".menu-dropdown-wrapper");
        if (dropdownWrapper) {
          element.addEventListener("mouseover", () => {
            if (!(lastOpen == element)) {
              pseudoNav.innerHTML = dropdownWrapper.innerHTML;
              pseudoNav.classList.remove("desktop-hide");
              overlay.classList.remove("desktop-hide");
            }
            lastOpen = element;
            /*
            hideAll();
            if (element.querySelector(".desktop-hide")) {
              element
                .querySelector(".desktop-hide")
                .classList.remove("desktop-hide");
            }*/
          });
          /*
          let dropdownWrapper = element.querySelector(".menu-dropdown-wrapper");
          if (dropdownWrapper) {
            dropdownWrapper.addEventListener("mouseleave", () => {
              overlay.classList.add("desktop-hide");
              if (menuActive == false) {
                dropdownWrapper.classList.add("desktop-hide");
              }
            });
          }*/
        }
      });
      overlay.addEventListener("mouseover", () => {
        overlay.classList.add("desktop-hide");
        pseudoNav.classList.add("desktop-hide");
      });
    }

    if (mobile.matches) {
      // get icons inside button by class
      let menuIcon = menuButton.querySelector(".icon-menu");
      let closeIcon = menuButton.querySelector(".icon-menu-close");

      menuButton.addEventListener("click", () => {
        if (menuWrapper.classList.contains("hidden")) {
          if (annoucmentbar) {
            annoucmentbar.classList.add("h-0");
            annoucmentbar.classList.remove("h-10");
          }
          menuWrapper.classList.remove("hidden");
          overlay.classList.remove("hidden");
          menuIcon.classList.add("hidden");
          closeIcon.classList.remove("hidden");
          this.dataset.open = true;
          window.scrollTo(0, 1);
          document.body.classList.add("mobile-menu-open");
        } else {
          if (annoucmentbar) {
            annoucmentbar.classList.remove("h-0");
            annoucmentbar.classList.add("h-10");
          }
          menuWrapper.classList.add("hidden");
          overlay.classList.add("hidden");
          menuIcon.classList.remove("hidden");
          closeIcon.classList.add("hidden");
          this.dataset.open = false;
          document.body.classList.remove("mobile-menu-open");
        }

        menuLink.forEach((element) => {
          if (
            element.getAttribute("style") == null ||
            element.getAttribute("style") == ""
          ) {
            if (element.getAttribute("data-has-dropdown") == "true") {
              let dropDownWrapper = element.querySelector(
                ".menu-dropdown-wrapper"
              );
              element.style.setProperty(
                "--content-height",
                dropDownWrapper.clientHeight + "px"
              );
              dropDownWrapper.classList.add("h-0");
            }
          }
        });
      });

      menuLink.forEach((element) => {
        if (element.getAttribute("data-has-dropdown") == "true") {
          let dropDownWrapper = element.querySelector(".menu-dropdown-wrapper");
          element.querySelector("a").addEventListener("click", (event) => {
            event.preventDefault();
            if (element.getAttribute("active") == "true") {
              element.removeAttribute("active");
              dropDownWrapper.classList.replace("h-detail", "h-0");
            } else {
              menuLink.forEach((e) => {
                if (e.getAttribute("data-has-dropdown") == "true") {
                  e.removeAttribute("active");
                  e.querySelector(".menu-dropdown-wrapper").classList.replace(
                    "h-detail",
                    "h-0"
                  );
                }
              });
              dropDownWrapper.classList.replace("h-0", "h-detail");
              element.setAttribute("active", "true");
            }
          });
        }
      });

      overlay.addEventListener("click", () => {
        menuWrapper.classList.add("hidden");
        overlay.classList.add("hidden");
      });
    }

    function hideAll() {
      menuDropdown.forEach((element) => {
        element.classList.add("desktop-hide");
      });
    }

    document.addEventListener("algolia-search-opened", () => {
      if (this.dataset.open === "true" && mobile.matches) {
        menuButton.click();
      }
    });

    this.observeElementHeight(this);
  }

  updateCSSVariable(height) {
    document.documentElement.style.setProperty(
      "--site-header-height",
      `${height}px`
    );
  }

  observeElementHeight(targetElement) {
    if (!targetElement) {
      console.error(`Element missing`);
      return null;
    }

    this.updateCSSVariable(targetElement.offsetHeight);

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (
          entry.target === targetElement &&
          entry.target instanceof HTMLElement
        ) {
          this.updateCSSVariable(entry.target.offsetHeight);
        }
      }
    });

    resizeObserver.observe(targetElement);

    return resizeObserver;
  }
}

// Use base DynamicSectionElement class to enable replacing of content loaded from Section Rendering API
customElements.define("site-header", SiteHeader);
